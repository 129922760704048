<template>
  <v-container fluid class="pt-0" style="display: flex; flex-direction: column; min-height: 100vh">
    <v-row style="flex: 0">
      <v-col class="mt-4" cols="12" sm="5" md="4">
        <v-card class="chart-container pa-3" height="220">
          <DoghnutChart :chartData="users_chart_data" :options="users_chart_data_options"></DoghnutChart>
        </v-card>
      </v-col>
      <v-col class="mt-4" cols="12" sm="7" md="8">
        <v-card class="chart-container pa-3" height="220">
          <BarChart></BarChart>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="flex: 1">
      <v-col cols="12" sm="5" md="4">
        <AddressElementSelect v-model="city_id"
                              label="Населений пункт" filled
                              select_type="city"
        />
        <AddressElementSelect :parent_id="city_id" v-model="streets"
                              label="Вулиця(-ки)" multiple filled
                              select_type="street" use_parent_id
                              :disabled="city_id"
        />
        <AddressElementSelect :parent_id="streets" v-model="buildings"
                              label="Будинок(-ки)" multiple filled
                              select_type="building" use_parent_id
                              :disabled="!streets.length"
        />
      </v-col>
      <v-col cols="12" sm="7" md="8">
        <v-card class="fill-height" style="display: flex; flex-direction: column">
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Пошук"
                color="grey darken-1"
                placeholder="Почніть вводити текст для пошуку"
                single-line
                hide-details
                class="px-4 mb-3"
                @input="searchFlatByText"
            ></v-text-field>
          </v-card-title>
          <v-card-text style="flex: 1 1 100%">
            <v-data-table
                :headers="headers"
                :items="flats"
                :items-per-page="15"
                no-data-text="Дані відсутні. Жодного запису не виявлено"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [5, 15, 30, -1],

                }"
                class="custom-table"
            >
              <template v-slot:item.personal_id="{ item }">
                <v-icon class="mr-2" color="success" size="22">mdi-file-document-outline</v-icon>
                <span>{{ item.personal_id }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DoghnutChart from "@/components/charts/DoghnutChart";
import BarChart from "@/components/charts/BarChart";

export default {
  name: "PersonalCabinetStats.vue",
  components: {
    DoghnutChart,
    BarChart,
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      search: '',
      city_id: null,
      streets: [],
      buildings: [],
      flats: [],
      headers: [
        {text: 'Особовий рах.', value: 'person_id', width: 150},
        {text: 'Адреса', value: 'address'},
        {text: 'Власник', value: 'flat_owner_short_name'},
        {text: 'Зареєстровано', value: 'count', width: 120, align: 'right', sortable: false}
      ],
      users_chart_data: {
        labels: ['Зареєстровано', 'Не зареєстровано'],
        datasets: [
          {
            borderWidth: 1,
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)'
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
            ],
            data: [60, 40]
          }
        ]
      },
      users_chart_data_options: {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  methods: {
    onCityChange(payload) {
      console.log('city', payload)
    },
    onStreetChange(payload) {
      console.log('street', payload)
    },
    onBuildingChange(payload) {
      console.log('building', payload)
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
    searchFlatByText() {
      console.log('search')
    },
    changeUserData() {
      let arr = [0,0,0]
      let dat = []
      arr.forEach(() => {
        dat.push(this.getRandomInt(1, 100))
      })
      this.users_chart_data = {
        labels: ['Зареєстровано', 'Не зареєстровано', 'Heinf'],
        datasets: [
          {
            borderWidth: 1,
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgb(54,235,78)',
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54,235,84,0.2)',
            ],
            data: dat
          }
        ]
      }
    }
  },
  watch: {
  },
  created() {
  }
}
</script>

<style scoped lang="scss">
  .custom-table {
    display: flex;
    flex-direction: column;
    height: 100%;

    :deep(.v-data-table__wrapper) {
      flex: 1;
    }

    :deep(.v-data-table__footer) {
      flex: 0;
    }

  }
  .chart-container {
    flex-grow: 1;
    min-height: 0;

    :deep(> div) {
      position: relative;
      height: 100%;
    }

    > div {
      position: relative;
      height: 100%;
    }
  }
</style>